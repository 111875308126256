import axios from "axios";
import React from "react";

export const Api = axios.create({
    baseURL: !true ? "https://sandbox.almanit.kz/jpost/api/v1/tracking": "https://jmart.kz/gw/jpost-courier/api/v1/tracking"
});

export const ApiRequest = (configs) => {
    try {
        return Api(configs); // -> response -> data ... do somth 
    } catch (e) {
        console.log("Er from the services ");
        console.log(e)
        return { error: e.toString() };
    }
} 
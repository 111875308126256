import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Maps } from "./components/Map";
import "./App.css"
function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/:packageId" component={Maps} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;

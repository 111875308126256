import React from 'react';
import className from 'classnames';
import styles from "./styles/Modal.module.scss"
import arrowDown from "../assets/icons/arrow-down.svg";
import arrowUp from "../assets/icons/arrow-up.svg";
export class Modal extends React.Component {

    state = {
        open: true
    }
    render() {
        const { open } = this.state;
        const { courierName, address, barcode, tel, city, id, sx, cipher, status } = this.props;
        console.log('modal.js props ' + this.props);
        return (
            <div className={styles.wrapper}>
                <div className={open ? styles.modal : className(styles.modal, styles.hide)}>
                    {id ? <React.Fragment>
                        <p  >Информация о доставке  <span style={{ textAlign: "center", verticalAlign: "middle" }} onClick={() => this.setState({ open: !this.state.open })}><img src={open ? arrowDown : arrowUp} style={{ float: "right" }} width="25px"></img></span>
                        </p>
                        <table >
                            <tbody>
                                {cipher ? <tr>
                                    <td >Номер заказа</td>
                                    <td>{cipher}</td>
                                </tr> : ''}

                                {status ? <tr>
                                    <td >Статус заказа</td>
                                    <td>{status}</td>
                                </tr> : ''}

                                {courierName ? <tr>
                                    <td >Имя курьера</td>
                                    <td>{courierName}</td>
                                </tr> : ''}

                                {tel ? <tr>
                                    <td>Телефон курьера</td>
                                    <td><a href={`tel:${tel}`}>{tel}</a></td>
                                </tr> : ''}

                                {barcode ? <tr>
                                    <td>Номер накладной</td>
                                    <td>{barcode}</td>
                                </tr> : ''}

                                {address ? <tr>
                                    <td>Адрес доставки</td>
                                    <td>{address}</td>
                                </tr> : ''}

                                {city ? <tr>
                                    <td>Город</td>
                                    <td>{city}</td>
                                </tr> : ""}
                            </tbody>
                        </table>
                    </React.Fragment> : <p style={{ textAlign: "center" }}>Информация не найдена</p>}
                </div>
            </div>
        );
    }
}

import React from "react";
import { YMaps, Map, Placemark, ZoomControl, Circle, GeoObject } from "react-yandex-maps";
import { Container, Row, Col } from "react-bootstrap";
import queryString from "query-string";
import { ApiRequest } from "../services";
import { Modal } from "./Modal";
var index = 0;
var interval = null;

const cords = [
    {
        "lat": 52.262141,
        "lon": 76.948178
    },
    {
        "lat": 52.262205,
        "lon": 76.948250
    },
    {
        "lat": 52.262250,
        "lon": 76.948310
    },
    {
        "lat": 52.262310,
        "lon": 76.948400
    },
    {
        "lat": 52.262410,
        "lon": 76.948500
    }
]

export class Maps extends React.Component {

    state = {
        center: [],
        zoom: 15,
        y_map: null,
        address: "",
        courierName: "",
        barcode: "",
        telNum: "",
        cityName: "",
        courierCoord: [null, null],
        receiverCord: [52.262141, 76.948500],
        simulation: false,
        reference: null,
        courierId: null,
        cipher: null
    }

    sortByRequestDate = (a, b) => {
        let comparision = 0;
        if (a.reqDate > b.reqDate) {
            comparision = -1;
        } else if (a.reqDate < b.reqDate) {
            comparision = 1;
        }
        return comparision;
    }

    drawByCoords = (response) => {
        if (response.coords) {
            let sortedResponse = response.coords.sort(this.sortByRequestDate);
            let courierInfo = sortedResponse[0];
            this.setState({ courierCoord: [courierInfo.lat, courierInfo.lon] });
        }
        this.setState({ receiverCord: [response.recepient.lat, response.recepient.lon]});
    }

    fillDeliveryDetails = (response) => {
        this.setState({
            courierId: response.courierId,
            courierName: response.courier,
            telNum: response.phone,
            cipher: response.cipher,
            status: response.status
        });
        if (response.status === 'Доставлено') {
            clearInterval(interval);
        }

        this.setState({
            barcode: response.barcode,
            address: response.recepient.address
        });
        this.setState({
            cityName: response.city.name
        });
    }

    getCourierCoords = async () => {
        const configs = {
            method: 'get',
            url: '/courier/' + this.props.match.params.packageId
        }
        try {
            const { data } = await ApiRequest(configs);
            this.drawByCoords(data);
            this.fillDeliveryDetails(data);
        } catch (e) {
            const { response } = e;
            if (response) { // Axios Errors
                const { data } = response;
                if (data.status >= 400) {
                    clearInterval(interval);
                    alert(data.message)
                }
            }
        }
    }

    addQueryParam = (zoom) => {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: '?zoom=' + zoom
        });
    }

    getCoordsSim = () => {
        const { courierName } = this.state
        try {
            if (!courierName) {
                this.setState({
                    courierName: "Мрозовский Ярослав",
                    telNum: "7470147212",
                    barcode: "200005555",
                    address: "геН.дюсенова;377;"
                });
            }
            this.setState({ courierCoord: [cords[index].lat, cords[index].lon] });
        } catch (e) {
            return e;
        }
        index++;
    }
    componentDidMount() {
        const { simulation } = this.state;
        const values = queryString.parse(this.props.location.search)
        if (values.zoom && !Number.isNaN(values.zoom)) {
            this.setState({ zoom: values.zoom })
        }
        if (simulation) {
            this.getCoordsSim();
            const timer = setInterval(this.getCoordsSim, 5000);
        } else {
            this.getCourierCoords();
            interval = setInterval(this.getCourierCoords, 90000);
        }
    }

    setCenter = (ref) => {
        const { courierCoord, reference } = this.state
        const { y_map } = this.state;
        if (!reference) {
            this.setState({ reference: ref });
            ref.events.add('boundschange', (e) => {
                if (e.get('newZoom') !== e.get('oldZoom')) {
                    this.addQueryParam(e.get('newZoom'));
                }
            })
        }
        if (y_map) {
            if (courierCoord && courierCoord[0] != null) {
                ref.setCenter(courierCoord, ref.action.zoom, { duration: 400 });
            } else {
                ref.setCenter(this.state.receiverCord, ref.action.zoom, { duration: 400 });
            }
        }
    }
    render() {
        const { packageId } = this.props.match.params;
        const { cipher, courierCoord, receiverCord, courierName, address, barcode, cityName, telNum, center, zoom, courierId, status } = this.state;

        return (
            <React.Fragment>
                {
                    status === 'Доставлено' ? null :
                    <div className="tracking-notice">
                        Обновление каждые <span>90</span> сек.
                    </div>
                }
                <Container fluid>
                    <Row>
                        <Col >
                            <YMaps>
                                <Map state={{ center, zoom }}
                                    onLoad={(ymaps) => this.setState({ y_map: ymaps })}
                                    instanceRef={ref => ref && this.setCenter(ref)}
                                    width="100%"
                                    height="100vh"
                                    modules={["templateLayoutFactory", "layout.ImageWithContent"]}
                                >
                                    <ZoomControl />
                                    
                                    {
                                        this.state.courierCoord[0] != null ? <Placemark geometry={courierCoord}
                                        properties={{
                                            iconContent : '<img src="https://jpost.kz/wp-content/uploads/2021/08/car-red.png" style="width: 35px; height: 48px;" />'
                                            }}
                                    /> : null }
                                    <Placemark properties={{
                                        iconContent : '<img src="https://jpost.kz/wp-content/uploads/2021/02/maker.png" style="width: 48px; height: 48px;" />'
                                        }} geometry={receiverCord} />
                                </Map>
                            </YMaps>
                            <Modal status={status} courierName={courierName} tel={telNum} barcode={barcode} cipher={cipher} address={address} city={cityName} id={courierId} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment >
        );
    }


}
// properties={{
//     hintContent: 'Это хинт',
//     balloonContent: 'Это балун'
// }
// }
//     modules={
//         ['geoObject.addon.balloon', 'geoObject.addon.hint']
//     }
